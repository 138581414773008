import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import BackendService from '../services/backendService';
import {
  Folder,
  FolderBreadCrumb,
  FolderEditOrCreateDTO,
} from '../services/TemplateService/Template.type';
import { deletedWorkspaceFoldersCacheKey } from './useDeletedWorkspaceFolders';
import { useMetrics } from './useMetrics';
import { presentationsSearchCacheKey } from './usePresentationsSearch';
import { recentTemplatesCacheKey } from './useRecentTemplates';
import useWorkSpace from './useWorkSpace';

const getParentFolderCrumb = (
  parentFolderId: string,
  folders: Folder[],
): FolderBreadCrumb => {
  const parentFolder = folders.find(folder => folder.id === parentFolderId) as Folder;
  return {
    id: parentFolder.id,
    title: parentFolder.title as string,
    parentId: parentFolder.parentId,
  };
};
const createFolderBreadCrums = (
  folders: Folder[],
  workspaceId: string,
  parentFolderId?: string | null,
) => {
  const breadCrumbs: FolderBreadCrumb[] = [];
  if (parentFolderId) {
    const activeFolder = folders.find(folder => folder.id === parentFolderId) as Folder;
    if (activeFolder) {
      breadCrumbs.push({
        id: activeFolder.id,
        title: activeFolder?.title ? activeFolder?.title : 'Untitled',
        parentId: activeFolder?.parentId ? activeFolder?.parentId : undefined,
      });

      while (
        breadCrumbs[breadCrumbs.length - 1].parentId &&
        breadCrumbs[breadCrumbs.length - 1].parentId !== workspaceId
      ) {
        const newBreadCrumb = getParentFolderCrumb(
          breadCrumbs[breadCrumbs.length - 1].parentId as string,
          folders,
        );
        breadCrumbs.push(newBreadCrumb);
      }
    }
  }

  return breadCrumbs.reverse();
};

export const workspaceFoldersCacheKey = 'workspaceFolders';

function useWorkspaceFolders(parentId?: string) {
  const client = useQueryClient();
  const { workspaceId } = useWorkSpace();
  const metrics = useMetrics();

  // Fetch all folders
  const { data: folders, isFetching: isFetchingFolders } = useQuery(
    [workspaceFoldersCacheKey],
    async _ => {
      if (!workspaceId) return Promise.resolve(undefined);
      const folders = await BackendService.instance.workspace.listWorkspaceFolders(
        workspaceId,
      );
      return folders;
    },
    {
      staleTime: 5000,
      refetchInterval: 5000,
      enabled: Boolean(workspaceId),
    },
  );
  const breadcrumbs = useMemo(() => {
    if (!folders || !workspaceId) return [];
    return createFolderBreadCrums(folders, workspaceId, parentId);
  }, [parentId, folders, workspaceId]);

  // save a folder by REST-call
  const { mutate: saveFolder, isLoading: isSavingFolder } = useMutation(
    ({
      folderConfig,
    }: {
      folderConfig: FolderEditOrCreateDTO;
      onSuccessFunction?: (data: Folder) => void | undefined;
    }) => {
      return BackendService.instance.template.saveFolder({
        ...folderConfig,
      });
    },
    {
      onSettled: () => {
        client.invalidateQueries([workspaceFoldersCacheKey]);
      },
      onSuccess: (data, { onSuccessFunction }) => {
        if (onSuccessFunction) {
          onSuccessFunction(data);
        }
      },
    },
  );

  // Create Folder
  const {
    mutate: createFolderWithConfig,
    isLoading: isCreatingFolder,
    isSuccess: isCreatingFolderSuccess,
  } = useMutation(
    ({
      folderConfig,
    }: {
      folderConfig: FolderEditOrCreateDTO;
      onSuccessFunction?: (data: Folder) => void | undefined;
    }) => {
      metrics.logEvent('Workspace.Folder.Create', { workspaceId });
      return BackendService.instance.template.newFolder({
        ...folderConfig,
        parentId: folderConfig.parentId ? folderConfig.parentId : workspaceId,
        isPrivate: false,
      });
    },
    {
      onSettled: () => {
        client.invalidateQueries([workspaceFoldersCacheKey]);
      },
      onSuccess: (data, { onSuccessFunction }) => {
        client.setQueryData<Folder[]>([workspaceFoldersCacheKey], prevFolders =>
          prevFolders ? [data, ...prevFolders] : [data],
        );
        if (onSuccessFunction) {
          onSuccessFunction(data);
        }
      },
    },
  );

  const {
    mutate: deleteFolder,
    isLoading: isDeletingFolder,
    isSuccess: isDeleteFolderSuccess,
    variables: deleteFolderVariables,
    reset: resetDeleteFolder,
  } = useMutation(
    async (folder: Folder) => {
      metrics.logEvent('Blueprint.Folder.Delete', { id: folder.id });
      return await BackendService.instance.template.deleteFolder(folder.id);
    },
    {
      onSettled: () => {
        client.invalidateQueries([workspaceFoldersCacheKey]);
        client.invalidateQueries([recentTemplatesCacheKey]);
        client.invalidateQueries([presentationsSearchCacheKey]);
      },
      onMutate: async folder => {
        await client.cancelQueries([workspaceFoldersCacheKey]);
        const previousFolders = client.getQueryData<Folder[]>([workspaceFoldersCacheKey]);
        client.setQueryData<Folder[] | undefined>([workspaceFoldersCacheKey], folders => {
          if (!folders) return folders;
          return folders.filter(f => f.id !== folder.id);
        });

        client.setQueryData<Folder[] | undefined>(
          [deletedWorkspaceFoldersCacheKey],
          folders => {
            if (!folders) return [folder];
            return [...folders, folder];
          },
        );
        return () => client.setQueryData([workspaceFoldersCacheKey], previousFolders);
      },
      onError: (err, bodyWithError, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
    },
  );
  const currentFolders = useMemo(() => {
    if (!folders) return [];
    if (parentId) {
      return folders?.filter((folder: Folder) => folder.parentId === parentId);
    }
    if (workspaceId) {
      return folders?.filter((folder: Folder) => folder.parentId === workspaceId);
    }
    return [];
  }, [folders, workspaceId, parentId]);
  return {
    createFolderWithConfig,
    allFolders: folders,
    currentFolders,
    isFetchingFolders,
    isSavingFolder,
    isCreatingFolder,
    isDeleteFolderSuccess,
    deleteFolderVariables,
    resetDeleteFolder,
    saveFolder,
    deleteFolder,
    isDeletingFolder,
    breadcrumbs,
    isCreatingFolderSuccess,
  };
}
export default useWorkspaceFolders;
