import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import BackendService from '../services/backendService';
import {
  Folder,
  FolderBreadCrumb,
  FolderEditOrCreateDTO,
} from '../services/TemplateService/Template.type';
import { deletedFoldersCacheKey } from './useDeletedFolders';
import { useMetrics } from './useMetrics';
import { presentationsSearchCacheKey } from './usePresentationsSearch';
import { recentTemplatesCacheKey } from './useRecentTemplates';

const getParentFolderCrumb = (
  parentFolderId: string,
  folders: Folder[],
): FolderBreadCrumb => {
  const parentFolder = folders.find(folder => folder.id === parentFolderId) as Folder;
  return {
    id: parentFolder.id,
    title: parentFolder.title as string,
    parentId: parentFolder.parentId,
  };
};
const createFolderBreadCrums = (folders: Folder[], parentFolderId?: string | null) => {
  const breadCrumbs: FolderBreadCrumb[] = [];
  if (parentFolderId) {
    const activeFolder = folders.find(folder => folder.id === parentFolderId) as Folder;
    if (activeFolder) {
      breadCrumbs.push({
        id: activeFolder.id,
        title: activeFolder?.title ? activeFolder?.title : 'Untitled',
        parentId: activeFolder?.parentId ? activeFolder?.parentId : undefined,
      });

      while (breadCrumbs[breadCrumbs.length - 1].parentId) {
        const newBreadCrumb = getParentFolderCrumb(
          breadCrumbs[breadCrumbs.length - 1].parentId as string,
          folders,
        );
        breadCrumbs.push(newBreadCrumb);
      }
    }
  }

  return breadCrumbs.reverse();
};

export const foldersCacheKey = ['folders'];

function useFolders(parentId?: string) {
  const client = useQueryClient();

  const metrics = useMetrics();

  // Fetch all folders
  const { data: folders, isFetching: isFetchingFolders } = useQuery(
    foldersCacheKey,
    async _ => {
      const folders = await BackendService.instance.template.listFolders();
      return folders;
    },
    { staleTime: 1000 * 60 * 5 },
  );

  const breadcrumbs = useMemo(() => {
    if (!folders) return [];
    return createFolderBreadCrums(folders, parentId);
  }, [parentId, folders]);

  // save a folder by REST-call
  const { mutate: saveFolder, isLoading: isSavingFolder } = useMutation(
    ({
      folderConfig,
    }: {
      folderConfig: FolderEditOrCreateDTO;
      onSuccessFunction?: (data: Folder) => void | undefined;
    }) => {
      metrics.logEvent('Blueprint.Folder.Save', { id: folderConfig.id });
      return BackendService.instance.template.saveFolder(folderConfig);
    },
    {
      onSettled: () => {
        client.invalidateQueries(foldersCacheKey);
      },
      onSuccess: (data, { onSuccessFunction }) => {
        if (onSuccessFunction) {
          onSuccessFunction(data);
        }
      },
    },
  );

  // Create Folder
  const {
    mutate: createFolderWithConfig,
    isLoading: isCreatingFolder,
    isSuccess: isCreateFolderSuccess,
  } = useMutation(
    ({
      folderConfig,
    }: {
      folderConfig: FolderEditOrCreateDTO;
      onSuccessFunction?: (data: Folder) => void | undefined;
    }) => {
      metrics.logEvent('Blueprint.Folder.Create');
      return BackendService.instance.template.newFolder(folderConfig);
    },
    {
      onSettled: () => {
        client.invalidateQueries(foldersCacheKey);
      },
      onSuccess: (data, { onSuccessFunction }) => {
        client.setQueryData<Folder[]>(foldersCacheKey, prevFolders =>
          prevFolders ? [data, ...prevFolders] : [data],
        );
        if (onSuccessFunction) {
          onSuccessFunction(data);
        }
      },
    },
  );
  const {
    mutate: deleteFolder,
    isLoading: isDeletingFolder,
    isSuccess: isDeletingFolderSuccess,
    variables: deleteFolderVariables,
    reset: resetDeleteFolder,
  } = useMutation(
    async (folder: Folder) => {
      metrics.logEvent('Blueprint.Folder.Delete', { id: folder.id });
      return await BackendService.instance.template.deleteFolder(folder.id);
    },
    {
      onSettled: () => {
        client.invalidateQueries(foldersCacheKey);
        client.invalidateQueries([recentTemplatesCacheKey]);
        client.invalidateQueries([presentationsSearchCacheKey]);
      },
      onMutate: async folder => {
        await client.cancelQueries(foldersCacheKey);
        const previousFolders = client.getQueryData<Folder[]>(foldersCacheKey);
        client.setQueryData<Folder[] | undefined>(foldersCacheKey, folders => {
          if (!folders) return folders;
          return folders.filter(f => f.id !== folder.id);
        });

        client.setQueryData<Folder[] | undefined>(deletedFoldersCacheKey, folders => {
          if (!folders) return [folder];
          return [...folders, folder];
        });

        // Return the snapshotted value
        return () => client.setQueryData(foldersCacheKey, previousFolders);
      },
      onError: (err, bodyWithError, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
    },
  );

  const currentFolders = useMemo(() => {
    if (!folders) return [];
    if (!parentId) return folders.filter((f: Folder) => !f.parentId);
    return folders?.filter((folder: Folder) => folder.parentId === parentId);
  }, [folders, parentId]);

  return {
    createFolderWithConfig,
    allFolders: folders,
    currentFolders,
    isFetchingFolders,
    isSavingFolder,
    isCreatingFolder,
    saveFolder,
    deleteFolder,
    isDeletingFolderSuccess,
    deleteFolderVariables,
    resetDeleteFolder,
    isDeletingFolder,
    breadcrumbs,
    isCreateFolderSuccess,
  };
}
export default useFolders;
