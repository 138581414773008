import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import BackendService from '../services/backendService';
import { Folder } from '../services/TemplateService/Template.type';
import { useMetrics } from './useMetrics';
import useWorkSpace from './useWorkSpace';
import { workspaceFoldersCacheKey } from './useWorkspaceFolders';

export const deletedWorkspaceFoldersCacheKey = 'deletedWorkspaceFolders';

function useDeletedWorkspaceFolders(parentId?: string) {
  const { workspaceId } = useWorkSpace();
  const client = useQueryClient();
  const cacheKey = [deletedWorkspaceFoldersCacheKey, workspaceId];
  const metrics = useMetrics();
  // Fetch all folders
  const { data: folders, isFetching: isFetchingFolders } = useQuery(
    cacheKey,
    async _ => {
      if (!workspaceId) return Promise.resolve([]);
      const folders = await BackendService.instance.workspace.listDeletedWorkspaceFolders(
        workspaceId,
      );
      return folders;
    },
    { staleTime: 10000, refetchInterval: 10000 },
  );

  const { mutate: restoreFolder, isLoading: isRestoringFolder } = useMutation(
    ({
      folderId,
    }: {
      folderId: string;
      onSuccessFunction?: (data: Folder) => void | undefined;
    }) => {
      metrics.logEvent('Folder.Restore', { id: folderId });
      if (!workspaceId) return Promise.resolve(undefined);

      return BackendService.instance.workspace.restoreWorkspaceFolder(
        workspaceId,
        folderId,
      );
    },
    {
      onSettled: () => {
        client.invalidateQueries(cacheKey);
        client.invalidateQueries([workspaceFoldersCacheKey, workspaceId]);
      },
      onSuccess: (data, { onSuccessFunction }) => {
        if (onSuccessFunction && data) {
          onSuccessFunction(data);
        }
      },
    },
  );

  const currentFolders = useMemo(() => {
    if (!folders) return [];
    return folders?.filter((folder: Folder) => folder.parentId === null);
  }, [folders, workspaceId, parentId]);

  return {
    allFolders: folders,
    currentFolders,
    restoreFolder,
    isRestoringFolder,
    isFetchingFolders,
  };
}
export default useDeletedWorkspaceFolders;
