import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import BackendService from '../services/backendService';
import { Folder } from '../services/TemplateService/Template.type';
import { foldersCacheKey } from './useFolders';
import { useMetrics } from './useMetrics';

export const deletedFoldersCacheKey = ['deletedFolders'];

function useDeletedFolders(parentId?: string) {
  const metrics = useMetrics();
  const client = useQueryClient();
  // Fetch all folders
  const { data: folders, isFetching: isFetchingFolders } = useQuery(
    deletedFoldersCacheKey,
    async _ => {
      const folders = await BackendService.instance.template.listDeletedFolders();
      return folders;
    },
    { staleTime: 1000 * 60 * 5 },
  );

  const { mutate: restoreFolder, isLoading: isRestoringFolder } = useMutation(
    ({
      folderId,
    }: {
      folderId: string;
      onSuccessFunction?: (data: Folder) => void | undefined;
    }) => {
      metrics.logEvent('Folder.Restore', { id: folderId });
      return BackendService.instance.template.restoreFolder(folderId);
    },
    {
      onSettled: () => {
        client.invalidateQueries(foldersCacheKey);
        client.invalidateQueries(deletedFoldersCacheKey);
      },
      onSuccess: (data, { onSuccessFunction }) => {
        if (onSuccessFunction) {
          onSuccessFunction(data);
        }
      },
    },
  );

  const currentFolders = useMemo(() => {
    if (!folders) return [];
    if (!parentId) return folders.filter((f: Folder) => !f.parentId);
    return folders?.filter((folder: Folder) => folder.parentId === parentId);
  }, [folders, parentId]);

  return {
    allFolders: folders,
    currentFolders,
    restoreFolder,
    isRestoringFolder,
    isFetchingFolders,
  };
}
export default useDeletedFolders;
